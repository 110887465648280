:root{
    --text-black: #292929;
}

@font-face {
    font-family: 'Roboto';
    src: url("/public/fonts/Roboto-Bold.ttf");
}

@font-face {
    font-family: 'Roboto Medium';
    src: url("/public/fonts/Roboto-Medium.ttf");
}

@font-face {
    font-family: 'Roboto Regular';
    src: url("/public/fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: 'Nimbus Sans';
    src: url("/public/fonts/nimbus-sans/NimbusSanL-Reg.otf");
}

@font-face {
    font-family: 'Nimbus Sans Bold';
    src: url("/public/fonts/nimbus-sans/NimbusSanL-Bol.otf");
}

@font-face {
    font-family: 'Minion Pro';
    src: url("/public/fonts/MinionPro-Regular.otf");
}

body{
    background-color: #fefefe!important;
}

.roboto-b {
    font-family: 'Roboto';
}

.carousel-container div:first-child{
    height: 100%!important;
}